import PrimaryButton from 'DesignComponents/Atoms/Buttons/PrimaryButton';
import SecondaryButton from 'DesignComponents/Atoms/Buttons/SecondaryButton';
import BodyText from 'DesignSystem/Typography/BodyText/BodyText';
import { useEffect, useRef } from 'react';
import Button from 'Shared/Components/Buttons/Button';
import lightColors from 'Theme/Light/light.colors';
import {
  ModalFooterActionAreaStyled, ModalFooterActionStyled, ModalFooterProps, ModalFooterStyled
} from 'DesignComponents/Organisms/Modal/ModalFooter';
import { useUiState } from 'Shared/Providers/UiState/UiStateProvider';
import { API_ROOT_PATH } from 'Shared/Constants/route';
import { setShippingCountry } from '../Pages/Checkout/checkout.requests';

import { useTranslations } from '../../context/init-data.context';
import { useHttpClient } from '../../lib/requests/HttpClientProvider';
import { useRouter } from '../../lib/router/router-hook';

export const SET_SHIPPINGDESTINATION_URL =
  API_ROOT_PATH + '/shipment/SetShippingDestination';

const CountryPickerModalFooter = ({
  getModalFooterHeight,
  displayModal,
  modalFooterContent,
}: ModalFooterProps) => {
  const router = useRouter();
  const client = useHttpClient();
  const ref = useRef<HTMLInputElement>(null);
  const { country, toggleModal } = useUiState();
  const {
    commonLabels: { cancel, chooseCountry },
  } = useTranslations();

  useEffect(() => {
    if (displayModal) {
      setTimeout(() => {
        if (getModalFooterHeight)
          getModalFooterHeight(ref.current?.offsetHeight);
      }, 310); // Wait for transition to finish then get the height from footer
    }
  }, [displayModal]);

  if (!modalFooterContent) return null;

  return (
    <ModalFooterStyled
      ref={ref}
      footerLayout={modalFooterContent.footerLayout}
      css={{
        '&::before': {
          background: lightColors.decorationGradientOpacity,
        },
      }}
    >
      <ModalFooterActionAreaStyled
        footerLayout={modalFooterContent.footerLayout}
      >
        <ModalFooterActionStyled footerLayout={modalFooterContent.footerLayout}>
          <Button<typeof PrimaryButton>
            element={'PrimaryButton'}
            props={{
              text: chooseCountry,
              color: 'Regular',
              hug:
                modalFooterContent.footerLayout === 'stacked'
                  ? 'width'
                  : undefined,
            }}
            onClick={async () => {
              await setShippingCountry(client, country.code);
              router.reload();
            }}
          />
        </ModalFooterActionStyled>

        <ModalFooterActionStyled>
          <Button<typeof SecondaryButton>
            element={'SecondaryButton'}
            props={{
              text: cancel,
              color: 'Regular',
              hug:
                modalFooterContent.footerLayout === 'stacked'
                  ? 'width'
                  : undefined,
            }}
            onClick={() => {
              toggleModal(false);
            }}
          />
        </ModalFooterActionStyled>
      </ModalFooterActionAreaStyled>
      {modalFooterContent.text &&
        modalFooterContent.footerLayout === 'sideBySide' && (
          <BodyText>{modalFooterContent.text}</BodyText>
        )}
    </ModalFooterStyled>
  );
};

export default CountryPickerModalFooter;
