import { HttpClient } from '../../../lib/requests/http-client';
import SwitchShippingDestinationResponseModel
  from '../../../Models/Shipping/SwitchShippingDestinationResponseModel.interface';
import CheckoutFormPatchModel from '../../../Models/Checkout/CustomerDetails/CheckoutFormPatchModel.interface';
import CheckoutFormUpdateModel from '../../../Models/Checkout/CustomerDetails/CheckoutFormUpdateModel.interface';
import CheckoutPaymentModel from '../../../Models/Checkout/Payment/CheckoutPaymentModel.interface';

export function switchShippingCountry(client: HttpClient, countryCode: string | undefined) {
  return client.post('api://shipment/SwitchShippingDestination')
    .withBody({countryCode})
    .go<SwitchShippingDestinationResponseModel>();
}

export function setShippingCountry(client: HttpClient, countryCode: string) {
  return client.post('api://shipment/SetShippingDestination')
    .withBody({countryCode})
    .go();
}

export function patchCheckoutData(
  client: HttpClient,
  data: CheckoutFormPatchModel,
  lang: string
) {
  return client.patch('api://checkout/UpdateUserDetails')
    .withQuery('language', lang)
    .withBody(data)
    .go()
}

export function getPaymentStatus(client: HttpClient, trackingNumber: string) {
  return client.get(`api://checkout/GetPaymentStatus?trackingNumber=${trackingNumber}`)
    .go<CheckoutPaymentModel>()
}

export function initializePayment(client: HttpClient, data: CheckoutFormUpdateModel, languageRoute: string){
  return client.post(`api://checkout/InitializePayment?language=${languageRoute}`)
    .withBody(data)
    .go<CheckoutPaymentModel>()
}